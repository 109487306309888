<template>
  <div>
    <!-- <div>生产看板123http://localhost:8080/kanban/production</div> -->
    <div class="container">
      <div class="bg-colore"></div>
      <div class="content"></div>
    </div>
    <div class="head">
      <h1>生产数据可视化大屏</h1>
      <div class="time" id="showTime">
        {{ currentTime }}
        <span class="fullscreen-icon" @click="toggleFullScreen"></span>
        <span class="return-icon" @click="navigateTo('/home')"></span>
      </div>
    </div>
    <div class="mainbox">
      <ul class="clearfix">
        <li>
          <!-- <div class="boxall" style="height: calc(58% - .15rem)"> -->
          <div class="boxall" style="height: calc(100% - 0.15rem)">
            <div class="alltitle">生产工单信息</div>
            <!-- <div class="boxnav" id=""> -->
            <!-- <div class="boxnav" id="" style="max-height: 410px; overflow-y: auto;"> -->
            <div class="boxnav" id="">
              <table border="0" cellspacing="0">
                <tbody>
                  <tr>
                    <th></th>
                    <th>订单编号</th>
                    <th>物料名称</th>
                    <th>备料状态</th>
                    <th>计划数量</th>
                    <th>完成数量</th>
                    <th>生产进度</th>
                    <th>计划开始时间</th>
                    <th>计划完成时间</th>
                    <th>状态</th>
                  </tr>
                  <tr v-for="(productionOrderData, index) in productionOrderData" :key="index">
                    <th>{{ index + 1 }}</th>
                    <td>{{ productionOrderData.production_order_number }}</td>
                    <td>{{ productionOrderData.product_name }}</td>
                    <td
                      v-if="productionWarningList.indexOf(productionOrderData.production_order) === -1"
                      style="color: #bae637"
                    >
                      正常
                    </td>
                    <td v-else style="color: #ff4d4f; font-weight: bold">库存不足</td>
                    <td>{{ productionOrderData.total_quantity }}</td>
                    <td>{{ productionOrderData.completed_quantity }}</td>
                    <td style="width: 240px">
                      <div style="display: flex; align-items: center">
                        <div style="flex-grow: 8; width: 80%">
                          <a-progress
                            :percent="(productionOrderData.completed_quantity / productionOrderData.total_quantity) * 100"
                            :show-info="false"
                            stroke-color="#fadb14"
                            style="opacity: 1"
                          />
                        </div>
                        <div style="flex-grow: 2; width: 20%">
                          {{ (productionOrderData.completed_quantity / productionOrderData.total_quantity).toFixed(2) * 100 }}%
                        </div>
                      </div>
                    </td>
                    <td>{{ productionOrderData.planned_start_time }}</td>
                    <td>{{ productionOrderData.planned_end_time }}</td>
                    <td
                      :style="{
                        color: getStatusColor(productionOrderData.production_order_status),
                        'font-weight': productionOrderData.production_order_status === '进行中' ? 'normal' : 'bold',
                      }"
                    >
                      {{ productionOrderData.production_order_status }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div class="boxall" style="height: calc(42% - .15rem)">
            <div class="alltitle">
              备料状态统计
            </div>
            <div class="boxnav" id="echarts6" style="width: 600px;height:280px;"></div>
          </div> -->
        </li>
        <!-- <li>
          <div class="boxall" style="height: calc(20% - .15rem)">
            <ul class="row h100 clearfix">
              <li class="col-6">
                <div class="sqzs h100">
                  <p>生产信息总览</p>
                  <h1><span>{{ production_order_total_count }}</span>单</h1>
                </div>
              </li>
              <li class="col-6">
                <ul class="row h100 clearfix">
                  <li class="col-4">
                    <div class="piebox" id="pe01" style="width: 400px;height:190px;top:10px"></div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="boxall" style="height: calc(38% - .15rem)">
            <div class="alltitle">
              生产进度
            </div>
            <div class="boxnav" id="echarts1" style="width: 1000px;height:200px;"></div>
          </div>
          <div class="boxall" style="height: calc(42% - .15rem)">
            <div class="alltitle">
              超期工单备料数量统计
            </div>
            <div class="boxnav" id="echarts4" style="width: 1000px;height:220px;"></div>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { productionProgressStats } from "@/api/kanban";
import { materialPreparationStats } from "@/api/kanban";
import { productionOrderWarningList } from "@/api/production";

import $ from "jquery";
import * as echarts from "echarts";
export default {
  components: {},
  data() {
    return {
      currentTime: "",
      production_order_total_count: null,
      production_order_normal_count: null,
      production_order_abnormal_count: null,
      productionOrderData: null,
      materialTypesStatus: null,
      materialOrderData: null,

      productionWarningList: [],
    };
  },
  methods: {
    toggleFullScreen() {
      const element = document.documentElement;

      if (!document.fullscreenElement) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    navigateTo(pathName) {
      this.$router.push({ path: pathName });
    },
    async fetchProductionOrderInfo() {
      try {
        const response = await productionProgressStats();
        //console.log(response);
        if (response) {
          const data = response; // Store response data for better readability
          this.productionOrderData = data.production_order_items;
          this.production_order_total_count = data.production_order_total_count;
          this.production_order_normal_count = data.production_order_normal_count;
          this.production_order_abnormal_count = data.production_order_abnormal_count;
          //console.log('Production Order Data:', this.productionOrderData);
          //console.log('production_order_total_count:', this.production_order_total_count);
          //console.log('production_order_normal_count:', this.production_order_normal_count);

          this.productionWarningList = await productionOrderWarningList({
            ids: data.production_order_items.map((item) => item.id),
          });
        } else {
          console.error("Invalid API response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching production order info:", error);
        throw error;
      }
    },
    async fetchMaterialTypesStatus() {
      try {
        const response = await materialPreparationStats();
        //console.log('xinxi', response);
        this.materialTypesStatus = response;
        this.materialOrderData = response.material_types_expired_items;
        //console.log('number', this.materialOrderData);
      } catch (error) {
        console.error("Error fetching material info:", error);
      }
    },

    updateTime() {
      const dt = new Date();
      const y = dt.getFullYear();
      const mt = dt.getMonth() + 1;
      const day = dt.getDate();
      const h = dt.getHours();
      const m = dt.getMinutes();
      const s = dt.getSeconds();

      this.currentTime = `${y}/${this.Appendzero(mt)}/${this.Appendzero(day)} ${this.Appendzero(h)}:${this.Appendzero(
        m
      )}:${this.Appendzero(s)}`;
    },
    Appendzero(obj) {
      return obj < 10 ? "0" + obj : obj;
    },
    adjustFontSize() {
      var whei = $(window).width();
      $("html").css({ fontSize: whei / 20 });
    },

    echarts1_1() {
      var myChart = echarts.init(document.getElementById("echarts1"));
      const productionOrderData = this.productionOrderData;
      const topTenOrders = productionOrderData.slice(0, 10);
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        grid: {
          top: "20%",
          right: "50",
          bottom: "20",
          left: "30",
        },
        legend: {
          data: ["计划数量", "生产数量"],
          right: "center",
          width: "100%",
          textStyle: {
            color: "#fff",
          },
          itemWidth: 12,
          itemHeight: 10,
        },
        xAxis: [
          {
            type: "category",
            data: topTenOrders.map((item) => item.production_order_number),
            axisLine: { lineStyle: { color: "rgba(255,255,255,.1)" } },
            axisLabel: { color: "rgba(255,255,255,.7)", fontSize: "8" },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "数量",
            nameTextStyle: { color: "white" },
            axisTick: { show: false },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              fontSize: 14,
              color: "rgba(255,255,255,.7)",
              margin: 1, // Adjust the margin to provide space for the labels
              rotate: 0, // Rotate the labels if needed
            },
            axisLine: {
              min: 0,
              max: Math.max(...topTenOrders.map((item) => item.total_quantity)),
              lineStyle: { color: "rgba(255,255,255,.1)" },
            },
          },
        ],
        series: [
          {
            name: "计划数量",
            type: "bar",
            data: topTenOrders.map((item) => item.total_quantity),
            barWidth: "15%",
            itemStyle: {
              borderRadius: 15,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8bd46e",
                },
                {
                  offset: 1,
                  color: "#09bcb7",
                },
              ]),
            },
            barGap: "0.2",
          },
          {
            name: "生产数量",
            type: "bar",
            data: topTenOrders.map((item) => item.completed_quantity),
            barWidth: "15%",
            itemStyle: {
              borderRadius: 15,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#248ff7",
                },
                {
                  offset: 1,
                  color: "#6851f1",
                },
              ]),
            },
            barGap: "0.2",
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    echarts4_1() {
      var myChart = echarts.init(document.getElementById("echarts4"));
      var myColor = ["#eb2100", "#eb3600", "#d0570e", "#d0a00e", "#34da62", "#00e9db", "#00c0e9", "#0096f3"];
      const materialOrderData = this.materialOrderData;
      const topTenOrders = materialOrderData.slice(0, 10);
      topTenOrders.sort((b, a) => b.demand_quantity - a.demand_quantity);
      var option = {
        grid: {
          left: "2%",
          top: "1%",
          right: "5%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
          },
        ],
        yAxis: [
          {
            axisTick: "none",
            axisLine: "none",
            offset: "7",
            axisLabel: {
              color: "rgba(255,255,255,.6)",
              fontSize: "14",
            },
            data: topTenOrders.map((item) => item.material_name),
          },
          {
            axisTick: "none",
            axisLine: "none",
            axisLabel: {
              color: "rgba(255,255,255,.6)",
              fontSize: "14",
            },
            data: topTenOrders.map((item) => item.demand_quantity),
          },
          {
            name: "单位：件",
            nameGap: "50",
            nameTextStyle: {
              color: "rgba(255,255,255,.6)",
              fontSize: "16",
            },
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            data: [],
          },
        ],
        series: [
          {
            name: "条",
            type: "bar",
            yAxisIndex: 0,
            data: topTenOrders.map((item) => item.prepared_quantity),
            label: {
              show: true,
              position: "right",
              formatter: function (param) {
                return param.value;
              },
              verticalOffset: 100,
              color: "rgba(255,255,255,.8)",
              fontSize: "12",
            },
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: "#03c893",
                },
                {
                  offset: 1,
                  color: "#0091ff",
                },
              ]),
              borderRadius: 15,
            },
            z: 2,
          },
          {
            name: "白框",
            type: "bar",
            yAxisIndex: 1,
            barGap: "-100%",
            data: topTenOrders.map((item) => item.demand_quantity),
            barWidth: 15,
            itemStyle: {
              color: "rgba(0,0,0,.2)",
              borderRadius: 15,
            },
            z: 1,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    echarts6_1() {
      var myChart = echarts.init(document.getElementById("echarts6"));
      var materialPreparationInfo = this.materialTypesStatus;
      //console.log(materialPreparationInfo);
      var option = {
        title: {
          text: materialPreparationInfo.material_types_total_count,
          subtext: "总备料种类数量",
          x: "center",
          y: "40%",
          textStyle: {
            color: "#fff",
            fontSize: 18,
            lineHeight: 10,
          },
          subtextStyle: {
            color: "#90979c",
            fontSize: 16,
            lineHeight: 10,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },

        visualMap: {
          show: false,
          min: 500,
          max: 600,
          inRange: {
            //colorLightness: [0, 1]
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "50%"],
            color: ["#FBFE27", "#FE5050"], //'#FBFE27','rgb(11,228,96)','#FE5050'
            data: [
              {
                value: materialPreparationInfo.material_types_unexpired_count,
                name: "备料未超期种类数",
              },
              {
                value: materialPreparationInfo.material_types_expired_count,
                name: "备料超期种类数",
              },
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",

            label: {
              formatter: ["{c|{c}种}", "{b|{b}}"].join("\n"),
              rich: {
                c: {
                  color: "rgb(241,246,104)",
                  fontSize: 20,
                  fontWeight: "bold",
                  lineHeight: 5,
                },
                b: {
                  color: "rgb(98,137,169)",
                  fontSize: 14,
                  height: 44,
                },
              },
            },
            labelLine: {
              lineStyle: {
                color: "rgb(98,137,169)",
              },
              smooth: 0.2,
              length: 10,
              length2: 20,
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    pe01_1() {
      var myChart = echarts.init(document.getElementById("pe01"));
      var txt = 0;
      //console.log('production_order_normal_count:', this.production_order_normal_count);
      //console.log('production_order_total_count:', this.production_order_total_count);
      if (this.production_order_total_count > 0) {
        txt = this.production_order_normal_count / this.production_order_total_count;
      }
      var txtPercentage = txt * 100;
      var option = {
        title: {
          text: "正常单数率\n" + txtPercentage.toFixed(0) + "%",
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: " #fff",
            fontSize: "18",
          },
        },
        color: "rgba(255,255,255,.3)",
        series: [
          {
            name: "Line 1",
            type: "pie",
            clockwise: true,
            radius: ["65%", "80%"],
            itemStyle: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
            emphasis: { scale: 0 },
            data: [
              {
                value: txt * 100,
                name: "正常单数",
                label: {
                  color: " rgba(255, 255, 255, .7)",
                },
                itemStyle: {
                  color: "#FBFE27",
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  textStyle: {
                    fontSize: 18,
                    fontWeight: "bold",
                  },
                },
              },
              {
                name: "超期单数",
                value: 100 - txt * 100,
                label: {
                  color: " rgba(255, 255, 255, .7)",
                },
                itemStyle: {
                  color: "#FE5050",
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  textStyle: {
                    fontSize: 20, // Adjust font size
                    fontWeight: "bold", // Adjust font weight
                    color: "rgba(255,255,255,.6)",
                  },
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getStatusColor(status) {
      switch (status) {
        case "进行中":
          return "#FBFE27";
        case "已超期":
          return "#FE5050";
        default:
          return "black";
      }
    },
  },
  async mounted() {
    // 先获取生产订单信息
    await this.fetchProductionOrderInfo();
    await this.fetchMaterialTypesStatus();
    // 然后再调用其他的方法
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000);

    this.adjustFontSize();
    $(window).resize(() => {
      this.adjustFontSize();
    });

    this.echarts1_1();
    this.echarts4_1();
    this.echarts6_1();
    this.pe01_1();
  },
};
</script>

<style scoped>
@charset "utf-8";
.boxnav::-webkit-scrollbar {
  width: 10x; /* width of the scrollbar */
}

.boxnav::-webkit-scrollbar-thumb {
  background-color: #000d4a; /* color of the scrollbar thumb */
  border-radius: 6px; /* roundness of the scrollbar thumb */
  /* border: 3px solid #2980b9;  */
}

.boxnav::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px; /* roundness of the scrollbar track */
}
.bg-colore {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000d4a url(../images/bg.jpg) center center;
  z-index: -1;
  background-size: cover;
  color: #fff;
  font-size: 0.1rem;
}

body {
  padding: 0px;
  margin: 0px;
  font-family: "微软雅黑";
}

html,
body {
  background: #000d4a url(../images/bg.jpg) center center;
  background-size: cover;
  color: #fff;
  font-size: 0.1rem;
}

li {
  list-style-type: none;
}

@font-face {
  font-family: electronicFont;
  src: url(../font/DS-DIGIT.TTF);
}

i {
  margin: 0px;
  padding: 0px;
  text-indent: 0px;
}

img {
  border: none;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #399bff;
}

a.active,
a:focus {
  outline: none !important;
  text-decoration: none;
}

ol,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a:hover {
  color: #06c;
  text-decoration: none !important;
}

html,
body {
  height: 100%;
}

.clearfix:after,
.clearfix:before {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

/* 谷哥滚动条样式 */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: #5bc0de;
}

::-webkit-scrollbar-track {
  background-color: #ddd;
}
.head {
  height: 1.05rem;
  background: url(../images/head_bg.png) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
}

.head h1 {
  color: #fff;
  text-align: center;
  font-size: 0.4rem;
  line-height: 0.8rem;
  letter-spacing: -1px;
  font-weight: 800;
}

.head h1 img {
  width: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}

.time {
  position: absolute;
  right: 0.15rem;
  top: 0;
  line-height: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.3rem;
  padding-right: 0.1rem;
  font-family: electronicFont;
}

.mainbox {
  padding: 0 0.2rem 0rem 0.2rem;
  height: calc(100% - 1.05rem);
}

.mainbox > ul {
  margin-left: -0.1rem;
  margin-right: -0.1rem;
  height: 100%;
}

.mainbox > ul > li {
  float: left;
  padding: 0 0.1rem;
  height: 100%;
  /* width: 40% */
  width: 100%;
}

.mainbox > ul > li:nth-child(2) {
  width: 60%;
}

.boxall {
  padding: 0 0.2rem 0.2rem 0.2rem;
  background: rgba(6, 48, 109, 0.5);
  position: relative;
  margin-bottom: 0.15rem;
  z-index: 10;
}

.alltitle {
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.5rem;
  position: relative;
  padding-left: 0.15rem;
}

.alltitle:before {
  position: absolute;
  height: 0.2rem;
  width: 4px;
  background: #49bcf7;
  border-radius: 5px;
  content: "";
  left: 0;
  top: 50%;
  margin-top: -0.1rem;
}

.boxnav {
  height: calc(100% - 0.5rem);
  position: relative;
}

.row > li {
  float: left;
  height: 100%;
}

.col-6 {
  width: 50%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333%;
}

.h100 {
  height: 100%;
}

.piebox {
  height: calc(100% - 0.5rem);
  position: relative;
}

.piebox:before {
  width: 0.6rem;
  height: 0.6rem;
  content: "";
  /* border: 1px solid #49bcf7; */
  border-radius: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.31rem;
  margin-top: -0.31rem;
  opacity: 0.7;
}

.sqzs {
  margin-right: 0.2rem;
}

.sqzs p {
  padding: 0.2rem 0 0.1rem 0;
  font-size: 0.25rem;
  color: white;
}

.sqzs h1 {
  height: calc(100% - 0.65rem);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  color: #fef000;
  font-weight: normal;
  letter-spacing: 2px;
  font-size: 0.5rem;
  justify-content: center;
  padding-bottom: 0.05rem;
}

.sqzs h1 span {
  font-size: 0.8rem;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

table {
  width: 100%;
  text-align: center;
}

table th {
  font-size: 0.16rem;
  color: white;
  background: rgba(0, 0, 0, 0.1);
}

table td {
  font-size: 0.16rem;
  color: rgba(255, 255, 255, 0.6);
}

table th,
table td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.1rem 0;
}
.fullscreen-icon {
  background: url(../images/Full_screen.png) no-repeat;
  background-size: contain;
  width: 20px; /* Adjust the width and height as needed */
  height: 20px;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px; /* Adjust the margin as needed */
}
.return-icon {
  background: url(../images/return.png) no-repeat;
  background-size: contain;
  width: 20px; /* Adjust the width and height as needed */
  height: 20px;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px; /* Adjust the margin as needed */
}
</style>
